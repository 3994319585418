<template>
  <div class="flex-v flex-align-center w100">
    <div
      class="flex-v w100 flex-align-center ignore-sol-header"
      :style="headerTop"
    >
      <div class="ignore-fz32 bold ignore-mt120 c-white z0">
        {{ detail.articleTitle }}
      </div>
      <div class="ignore-fz16 ignore-mt8 uppercase c-cac z0">
        {{ detail.source }}
      </div>
      <div class="z0 ignore-color-line ignore-mt8"></div>
      <div class="flex ignore-sol-tab flex-nowrap">
        <div
          class="ignore-type-item flex flex-1 pointer flex-align-center"
          v-for="(item, index) in solutionList"
          :key="index"
          @mouseenter="item.mouse = true"
          @mouseleave="item.mouse = false"
          @click="toDetail(item)"
        >
          <img
            v-lazy="activeBg"
            class="ignore-active-bg"
            :class="
              activeSolution == item.channelId
                ? 'active-bg-show'
                : item.mouse == true
                ? 'active-bg-show'
                : item.mouse == false
                ? 'active-bg-none'
                : 'active-bg-no'
            "
          />
          <div class="flex-v flex-1 flex-align-center flex-pack-center">
            <img
              class="ignore-sol-icon"
              v-lazy="
                activeSolution == item.channelId || item.mouse == true
                  ? item.coverImg
                  : item.coverImg2
              "
            />
            <div
              class="flex-v flex-align-center"
            >
              <div
                class="ignore-fz12 bold text-center"
                v-html="item.channelName2"
                style="white-space: pre-wrap"
              ></div>
            </div>
          </div>
          <div
            class="ignore-grey-vertical-line"
            v-if="index != solutionList.length - 1"
          ></div>
        </div>
      </div>
    </div>
    <div
      class="ignore-sol-body flex-v flex-align-center"
      ref="solHeader"
      :style="bodyTop"
    >
      <!-- <div class="ignore-fz80 ignore-mt40 uppercase c-ddd bold">
        about the system
      </div>
      <div class="ignore-fz48 uppercase c-399 bold">
        {{ detail.articleTitle }}
      </div>
      <div class="ignore-sol-grey-line ignore-mt60"></div> -->
      <div v-html="detail.articleContent" class="ignore-html"></div>
    </div>
  </div>
</template>

<script>
window.addEventListener("beforeunload", function () {
  window.scrollTo(0, 0);
});
import activeBg from "@/assets/home/active-bg.png";
import { getSolutionList, getSolutionDetail } from "@/api/solution";

export default {
  name: "solution",
  data() {
    return {
      activeBg,
      solutionList: [],
      activeSolution: 0,
      headerTop: {
        // marginTop: "0px",
        // position: "relative",
        background: "",
        backgroundSize: "",
      },
      bodyTop: {
        // paddingTop: "0px",
      },
      detail: {},
    };
  },
  computed: {
    solutionId() {
      return this.$route.query.solId;
    },
  },
  methods: {
    solScroll() {
      if (this.$refs.solHeader.getBoundingClientRect().top == 70) {
        // this.headerTop.position = "relative";
        // this.headerTop.marginTop = "0px";
        // this.bodyTop.paddingTop = "0px";
      } else if (
        this.$refs.solHeader.getBoundingClientRect().top > -150 &&
        this.headerTop.position == "fixed"
      ) {
        // this.headerTop.position = "relative";
        // this.headerTop.marginTop = "0px";
        // this.bodyTop.paddingTop = "0px";
      } else if (this.$refs.solHeader.getBoundingClientRect().top < 220) {
        // this.headerTop.marginTop = "-220px";
        // this.headerTop.position = "fixed";
        // this.bodyTop.paddingTop = "430px";
      } else {
        // this.headerTop.position = "relative";
        // this.headerTop.marginTop = "0px";
        // this.bodyTop.paddingTop = "0px";
      }
    },
    solScroll2() {
      if (this.$refs.solHeader.getBoundingClientRect().top == 70) {
        // this.headerTop.position = "relative";
        // this.headerTop.marginTop = "0px";
        // this.bodyTop.paddingTop = "0px";
      } else if (
        this.$refs.solHeader.getBoundingClientRect().top > -156 &&
        this.headerTop.position == "fixed"
      ) {
        // this.headerTop.position = "relative";
        // this.headerTop.marginTop = "0px";
        // this.bodyTop.paddingTop = "0px";
      } else if (this.$refs.solHeader.getBoundingClientRect().top < 150) {
        // this.headerTop.marginTop = "-200px";
        // this.headerTop.position = "fixed";
        // this.bodyTop.paddingTop = "360px";
      } else {
        // this.headerTop.position = "relative";
        // this.headerTop.marginTop = "0px";
        // this.bodyTop.paddingTop = "0px";
      }
    },
    initScroll() {
      if (this.headerTop.position == "fixed") {
        window.scrollTo(0, 250);
      }
    },
    getSolutionList() {
      getSolutionList().then((res) => {
        let list = res;
        res.forEach((item) => {
          item.mouse = -1;
        });
        this.solutionList = list;
        if (this.$route.query.solId) {
          let solItem = this.solutionList.find(
            (item) => item.channelId == this.$route.query.solId
          );
          this.getDetail(solItem);
        } else {
          this.getDetail(list[0]);
        }
      });
    },
    toDetail(item) {
      this.$router.push({
        path: "/solution",
        query: {
          solId: item.channelId,
          name: item.channelNameRemark,
        },
      });
    },
    getDetail(item) {
      this.activeSolution = item.channelId;
      getSolutionDetail(this.activeSolution).then((res) => {
        this.detail = res[0];
        this.headerTop.backgroundSize = "";
        this.headerTop.background =
          "url('" +
          this.detail.coverUrl +
          this.detail.coverPath +
          this.detail.articleCover +
          "') 0% 0% /100% 320px no-repeat";
      });
      // this.$router.push({ query: {} });
      this.initScroll();
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.solScroll);
  },
  mounted() {
    if (window.screen.width <= 1366) {
      window.addEventListener("scroll", this.solScroll2);
    } else {
      window.addEventListener("scroll", this.solScroll);
    }
    this.getSolutionList();
  },
  activated() {
    this.solutionList.forEach((item) => {
      item.mouse = -1;
    });
    window.scrollTo(0, 0);
    // this.headerTop.position = "relative";
    // this.headerTop.marginTop = "0px";
    if (this.solutionList.length > 0 && this.$route.query.solId) {
      let solItem = this.solutionList.find(
        (item) => item.channelId == this.$route.query.solId
      );
      this.getDetail(solItem);
    }
  },
};
</script>

<style lang="scss" scoped>
.ignore-grey-vertical-line {
  width: 1px;
  height: 36px;
  background: #e2e2e2;
}
.ignore-color-line {
  width: 50px;
  height: 3px;
  background: linear-gradient(-90deg, #34b5ff, #39e274);
  border-radius: 2px;
}
.ignore-sol-grey-line {
  width: 100%;
  height: 3px;
  background: #ddd;
}
.ignore-sol-header {
  z-index: 2;
  width: 100%;
  height: 390px;
  background-color: #fff;
  -webkit-transform: translateZ(0);
  left: 0;

  .ignore-sol-tab {
    margin-top: 68px;
    width: 1500px;
    height: 100px;
    background: #fff;

    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    .ignore-type-item {
      height: 100px;
      position: relative;
      .ignore-active-bg {
        position: absolute;
        top: 0;
        height: 100px;
        margin: 0 auto; /*水平居中*/
        left: 0; /*此处不能省略，且为0*/
        right: 0; /*此处不能省略，且为0*/
      }
      .ignore-sol-icon {
        height: 50px;
        width: 50px;
      }
    }
  }
}
.ignore-sol-body {
  width: 100%;
  min-height: 500px;
  background: #fff;
  box-sizing: content-box;
  padding-bottom: 40px;
}
.ignore-html {
  width: 1500px;
}
.ignore-html >>> img {
  width: 1500px !important;
  margin: 0 auto;
}
.active-bg-show {
  width: 100%;
  animation: moveShow 0.7s;
}
.active-bg-none {
  width: 0px;
  animation: moveNone 0.7s;
}
.active-bg-no {
  width: 0px;
}
@keyframes moveShow {
  0% {
    width: 10px;
  }
  100% {
    width: 100%;
  }
}
@keyframes moveNone {
  0% {
    width: 100%;
  }
  100% {
    width: 0px;
  }
}
</style>
<!-- <style scoped>
@media (max-width: 1366px) {
  .ignore-sol-header {
    height: 310px !important;
    background-size: 100% 260px !important;
  }
  .ignore-sol-tab {
    margin-top: 48px !important;
    width: 900px !important;
    height: 70px !important;
  }
  .ignore-type-item {
    height: 70px !important;
  }
  .ignore-active-bg {
    height: 70px !important;
  }
  .ignore-sol-header .ignore-mt120 {
    margin-top: 100px !important;
  }
  .ignore-sol-header .ignore-mt30 {
    margin-top: 12px !important;
  }
  .ignore-sol-header .ignore-fz32 {
    font-size: 26px !important;
  }
  .ignore-sol-tab {
    width: 900px !important;
    height: 70px !important;
  }
  .ignore-type-item {
    height: 70px !important;
  }
  .ignore-active-bg {
    height: 70px !important;
  }
  .ignore-sol-icon {
    height: 30px !important;
    width: 30px !important;
  }
  .ignore-html {
    width: 900px !important;
  }
  .ignore-html >>> img {
    max-width: 900px !important;
    margin: 0 auto;
  }
}
</style> -->