var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-v flex-align-center w100"},[_c('div',{staticClass:"flex-v w100 flex-align-center ignore-sol-header",style:(_vm.headerTop)},[_c('div',{staticClass:"ignore-fz32 bold ignore-mt120 c-white z0"},[_vm._v(" "+_vm._s(_vm.detail.articleTitle)+" ")]),_c('div',{staticClass:"ignore-fz16 ignore-mt8 uppercase c-cac z0"},[_vm._v(" "+_vm._s(_vm.detail.source)+" ")]),_c('div',{staticClass:"z0 ignore-color-line ignore-mt8"}),_c('div',{staticClass:"flex ignore-sol-tab flex-nowrap"},_vm._l((_vm.solutionList),function(item,index){return _c('div',{key:index,staticClass:"ignore-type-item flex flex-1 pointer flex-align-center",on:{"mouseenter":function($event){item.mouse = true},"mouseleave":function($event){item.mouse = false},"click":function($event){return _vm.toDetail(item)}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.activeBg),expression:"activeBg"}],staticClass:"ignore-active-bg",class:_vm.activeSolution == item.channelId
              ? 'active-bg-show'
              : item.mouse == true
              ? 'active-bg-show'
              : item.mouse == false
              ? 'active-bg-none'
              : 'active-bg-no'}),_c('div',{staticClass:"flex-v flex-1 flex-align-center flex-pack-center"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
              _vm.activeSolution == item.channelId || item.mouse == true
                ? item.coverImg
                : item.coverImg2
            ),expression:"\n              activeSolution == item.channelId || item.mouse == true\n                ? item.coverImg\n                : item.coverImg2\n            "}],staticClass:"ignore-sol-icon"}),_c('div',{staticClass:"flex-v flex-align-center"},[_c('div',{staticClass:"ignore-fz12 bold text-center",staticStyle:{"white-space":"pre-wrap"},domProps:{"innerHTML":_vm._s(item.channelName2)}})])]),(index != _vm.solutionList.length - 1)?_c('div',{staticClass:"ignore-grey-vertical-line"}):_vm._e()])}),0)]),_c('div',{ref:"solHeader",staticClass:"ignore-sol-body flex-v flex-align-center",style:(_vm.bodyTop)},[_c('div',{staticClass:"ignore-html",domProps:{"innerHTML":_vm._s(_vm.detail.articleContent)}})])])}
var staticRenderFns = []

export { render, staticRenderFns }